import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const CondensaRental = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>Condensa Rental</h1>
          <h2>Introduction</h2>
          <p>
            I was given the task of creating a construction equipment rental
            website. The website was supposed to be incredibly simple and
            transparent so that no one would have any problems with the service.
            The website was supposed to handle a large database of products
            divided into categories. The customer was supposed to be able to
            edit each product and add accessories to the equipment. The project
            also assumed the integration of the website with the existing
            warehouse program. Unfortunately, this idea was canceled by too high
            integration costs.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/condensa_rental.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot_condensa_rental.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default CondensaRental;

export function Head() {
  return <title>Condensa rental</title>;
}
